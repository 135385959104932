import { createStore } from "vuex";
import mainConfig from "../main.config.js";
import axios from "axios";

// Create a new store instance.
const store = createStore({
	state() {
		return {
			pois: [],
			categories: [],
			currentCategory: 'All',
			userPosition: null,
			preselectedPOI:null,
			viewMode:'No-AR',
			ausstellung: false,
			sidebarOpen:false,
			isMobile: false,
			showFullScreen: false,
			currentZoom: null
		};
	},
	actions: {
		GetAllPOIs({ state, commit }) {
			return state.pois.length != 0
				? new Promise((resolve) => resolve(state.poi))
				: axios
						.get(mainConfig.CMS_BASE_URL + "/api/pois?populate=*&pagination[start]=0&pagination[limit]=100")
						.then((response) => {
							commit("SetPOIs", response.data.data);
						})
						.catch((error) => {
							console.log("error while loading data:", error);
						});
		},
		GetAllCategories({ state, commit }) {
			return state.pois.length != 0
				? new Promise((resolve) => resolve(state.poi))
				: axios
						.get(mainConfig.CMS_BASE_URL + "/api/categories?populate=*&&sort=Name")
						.then((response) => {
							commit("SetCategories", response.data.data);
						})
						.catch((error) => {
							console.log("error while loading data:", error);
						});
		},
	},
	mutations: {
		SetPOIs: (state, data) => {
			state.pois = data;
		},
		SetCategories: (state, data) => {
			state.categories = data;
		},
		SetCategory: (state, data) => {
			state.currentCategory = data;
		},
		SetUserPosition: (state, data) => {
			state.userPosition = data;
		},
		SetViewMode: (state, data) => {
			state.viewMode = data;
		},
		SetAusstellungsMode: (state, data) => {
			state.ausstellung = true;
		},
		SetPreselectedPOI:(state,data)=>{
			state.preselectedPOI = data;
		},
		SetSidebar:(state,data)=>{
			state.sidebarOpen = data;
		}
		,
		SetMobile:(state,data)=>{
			state.isMobile= data;
		},
		ShowFullScreen: (state, data) => {
			state.showFullScreen = data;
		},
		SetCurrentZoom: (state, data) => {
			state.currentZoom = data;
		},

	},
});

export default store;
