<template>
  <div>
    <div
      class="map-page-container"
      :class="{ noCategoryText: this.CheckVisibility() }"
    >
      <div
        class="map bg-white"
        id="mapid"
        ref="map"
        :class="{ ausstellung: this.$store.state.ausstellung }"
      >
        <button
          class="w-10 h-10 bg-white hover:bg-grey nav-button absolute right-0 target"
          :class="{ mobileHoverWhite: this.$store.state.isMobile }"
          v-if="this.$store.state.userPosition && this.GetUserVisibility"
          @click="this.GoToUser()"
        >
          <Target />
        </button>
      </div>

      <Overview v-if="this.$store.state.pois.length != 0" />
    </div>
    <SinglePOI />
  </div>
</template>

<style src="leaflet/dist/leaflet.css"></style>

<script>
import { map, divIcon, Marker, tileLayer, layerGroup, latLng } from "leaflet";
import Overview from "../components/Overview.vue";
import SinglePOI from "../components/SinglePOI.vue";

import POIMapMarker from "../components/POIMapMarker.vue";
import UserMapMarker from "../components/UserMapMarker.vue";
import { render, createApp } from "vue";
import store from "../store/store";
import router from "../router";
import DMXService from "../services/DMXService.js";
import Target from "../assets/Icons/Target.vue";

export default {
  name: "Map",
  props: {
    viewPoint: {
      default: [52.399579, 13.06019], //52.408968, 13.030146
    },
    zoom: {
      default: 14.25,
    },
    locations: {
      default: () => [],
    },
    mapBounds: {
      default: [
        [52.381, 13.03],
        [52.426, 13.103],
      ],
    },
    offset: {
      default: {
        longitude: +0.0018, //+0.0014,
        latitude: -0.0009, //-0.0012,
      },
    },
  },
  components: { Overview, SinglePOI, Target },
  data() {
    return {
      mymap: null,
      firstSelectedId: null,
      selcondSelectedId: null,
      admin: false,
      userMarker: null,
      userPosition: null,
      userMarkerGroup: null,
      poiMarkerGroup: null,
      pois: [],
      poiComponents: [],
      userComponent: null,
    };
  },
  watch: {
    "$store.state.pois": function () {
      if (this.pois.length == 0) {
        this.pois = this.$store.state.pois;
        this.updatePOIs();
        this.JumpToPOI();
        //this.redraw();
      }
    },
    "$store.state.currentCategory": function () {
      this.SendCategoryDMX();
      this.Recenter();
      this.$router.push({ name: "Map", params: { id: null } });
    },
    $route: function () {
      this.SendCategoryDMX();
      this.JumpToPOI();
    },
  },
  mounted() {
    //this.$store.commit("SetSidebar", false);
    this.pois = this.$store.state.pois;
    this.mymap = map("mapid", {
      zoomControl: false,
      zoomSnap: 0.25,
      maxBoundsViscosity: 1.0,
    }).setView(this.$props.viewPoint, this.zoom);
    this.$store.commit("SetCurrentZoom", this.mymap.getZoom());

    this.mymap.on("zoom", () => {
      console.log("ZOOM ", this.mymap.getZoom());
      this.$store.commit("SetCurrentZoom", this.mymap.getZoom());
    });

    var bounds = this.mapBounds;
    this.mymap.setMaxBounds(bounds);
    this.redraw();
    this.updatePOIs();
    this.updateUserLocation();

    this.JumpToPOI();
    this.SendCategoryDMX();

    if (
      this.$store.state.viewMode == "AR" ||
      this.$store.state.ausstellung == true
    ) {
      return;
    }

    if (navigator.geolocation) {
      //after the user indicates that they want to turn on continuous location-tracking
      //navigator.geolocation.getCurrentPosition(this.updateUserLocation);
      navigator.geolocation.watchPosition(this.updateUserLocation);
    }
  },
  beforeUnmount() {
    this.poiComponents.map((poiComponent) => {
      poiComponent.unmount();
    });
    this.poiComponents = [];

    this.userComponent?.unmount();
  },

  computed: {
    GetUserVisibility() {
      if (this.$store.state.userPosition == null) {
        return true;
      }

      if (
        this.$store.state.userPosition[0] >= this.mapBounds[0][0] &&
        this.$store.state.userPosition[0] <= this.mapBounds[1][0] &&
        this.$store.state.userPosition[1] >= this.mapBounds[0][1] &&
        this.$store.state.userPosition[1] <= this.mapBounds[1][1]
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    CheckVisibility() {
      return this.$store.state.isMobile && this.$route.params.id != "";
    },
    Recenter() {
      this.mymap.setView(this.$props.viewPoint, this.zoom);
    },
    JumpToPOI() {
      var foundPOI = this.$store.state.pois.find((poi) => {
        return poi.id == this.$route.params.id;
      });

      console.log(
        "JumpToPoi",
        this.$route.params.id,
        foundPOI,
        this.$store.state.pois
      );

      if (foundPOI && foundPOI.attributes.Geolocation) {
        var offsetX = 0;
        var offsetY = 0;

        if (this.$store.state.isMobile) {
          offsetY = -0.001;
          offsetX = -0.0009;
        }
        //this.mymap.setZoom(16);

        this.mymap.setView(
          [
            this.getOffset(
              foundPOI.attributes.Geolocation.latitude +
                offsetY +
                this.offset.latitude
            ),
            foundPOI.attributes.Geolocation.longitude +
              offsetX +
              this.offset.longitude,
          ],
          17
        );

        // this.mymap.panTo(
        //   new latLng(
        //     foundPOI.attributes.Geolocation.latitude + offset,
        //     foundPOI.attributes.Geolocation.longitude
        //   )
        // );
      } else {
        this.Recenter();
      }
    },
    SendCategoryDMX() {
      console.log("Map Mounted");
      if (
        this.$route.name == "Landing" ||
        this.$route.name == "ImpressumPage" ||
        this.$route.name == "ARVewer" ||
        this.$route.params.id != ""
      ) {
        return;
      }

      console.log("Map Mounted after");

      var poisInCategory = [];

      if (this.$store.state.currentCategory == null) {
        poisInCategory = this.pois;
      } else {
        poisInCategory = this.pois.filter((poi) => {
          if (typeof poi.attributes.categories == "undefined") {
            return false;
          }
          var cat = Object.values(poi.attributes.categories.data).find(
            (cat) => cat.attributes.Name == this.$store.state.currentCategory
          );
          return cat != null;
        });
      }

      var dmx_ids = [];
      poisInCategory.map((poi) => {
        if (poi.attributes.DMX_IDs != null) {
          var ids = poi.attributes.DMX_IDs.split(",").map(Number);
          dmx_ids.push(...ids);
        }
      });
      var catInfo = this.$store.state.categories.find(
        (cat) => cat.attributes.Name == this.$store.state.currentCategory
      );
      var color = "#195b03";

      if (catInfo != null) {
        color = catInfo.attributes.ColorString;
      }

      if (this.$store.state.currentCategory == "All") {
        dmx_ids = [...Array(512).keys()];
        dmx_ids.splice(111, 1);
      }

      DMXService.SendDMXMessage({
        channels: dmx_ids,
        color: color,
      });
    },

    GoToUser() {
      if (this.$store.state.userPosition == null) {
        return;
      }
      this.mymap.panTo(
        new latLng(
          this.$store.state.userPosition[0],
          this.$store.state.userPosition[1]
        )
      );
    },

    redraw() {
      this.mymap.eachLayer((layer) => {
        layer.remove();
      });

      tileLayer("/Tiles/{z}/{x}/{y}.png", {
        maxZoom: 18,
        minZoom: 14,
        tileSize: 256,
      }).addTo(this.mymap);
    },
    resetPOIs() {
      this.pois.map((poi) => {
        poi.preselected = false;
      });
    },

    updateUserLocation(position, updateMapPos = false) {
      if (position != null) {
        this.$store.commit("SetUserPosition", [
          this.getOffset(position.coords.latitude + this.offset.latitude),
          position.coords.longitude + this.offset.longitude,
          //position.coords.latitude - 0.0013,
          //position.coords.longitude + 0.002,
        ]);
      }

      if (this.$store.state.userPosition == null) {
        return;
      }

      try {
        this.userComponent?.unmount();
      } catch (e) {
        console.warn("COULD NOT UNMOUNT USER");
      }

      //Init User Marker
      var htmlString = "<div id='user'> </div>";

      var className = "map-icon-user ";
      this.userMarker = divIcon({
        className: className,
        html: htmlString,
        id: "test",
      });

      if (this.userMarkerGroup != null) {
        this.userMarkerGroup.clearLayers();
      }
      var marker = new Marker(this.$store.state.userPosition, {
        icon: this.userMarker,
      });
      this.userMarkerGroup = new layerGroup([marker]).addTo(this.mymap);

      this.userComponent = createApp(UserMapMarker);
      this.userComponent.use(store);
      var el = document.getElementById("user");
      try {
        this.userComponent.mount(el);
      } catch (e) {
        console.warn("COULD NOT MOUNT USER");
      }

      if (updateMapPos) {
        this.GoToUser();
      }
    },

    getOffset(lat) {
      var startLat = 52.4032275663;
      var diff = startLat - lat;
      var r = 0;
      if (lat >= startLat) {
        r = lat + diff * diff * 5;
      } else {
        r = lat - diff * diff * 4;
      }

      //console.log("GET OFFSET ", startLat, diff, lat, r);
      return r;
    },

    updatePOIs() {
      if (this.poiMarkerGroup != null) {
        this.poiMarkerGroup.clearLayers();
        this.poiComponents.map((poiComponent) => {
          poiComponent.unmount();
        });
        this.poiComponents = [];
      }
      var markers = [];

      this.pois.forEach((poi) => {
        poi.isClose = false;
        if (poi.attributes.Geolocation == null) {
          return;
        }

        //CHECK CATEGORY COLOR
        var htmlString = `<div id='poi-${poi.id}'></div>`;
        var className = "map-icon ";

        if (poi.id == 34) {
          className += "hausBrandenburg";
        }

        var myIcon = divIcon({ className: className, html: htmlString });
        var geoLocation = [
          this.getOffset(
            poi.attributes.Geolocation.latitude + this.offset.latitude
          ),
          poi.attributes.Geolocation.longitude + this.offset.longitude,
        ];

        var marker = new Marker(geoLocation, { icon: myIcon });
        markers.push(marker);
      });
      this.poiMarkerGroup = layerGroup(markers).addTo(this.mymap);

      this.pois.forEach((poi) => {
        var props = { poiData: poi };
        let vNode = createApp(POIMapMarker, props);
        vNode.use(store);
        vNode.use(router);
        this.poiComponents.push(vNode);
        var el = document.getElementById("poi-" + poi.id);
        if (el != null) {
          //render(vNode, el);
          vNode.mount(el);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.user {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
  background-color: blue;
  border-radius: 10px;
  border: 2px solid #000;
}
.map-page-container {
  display: flex;
  align-items: stretch;
  height: calc(100% - 45px);
  position: fixed;
  width: 100%;
  top: 45px;
}
.map {
  width: calc(100% - 600px);
  padding: 0.5rem;
  height: 100%;
}

.ausstellung.map {
  width: 50%;
}

.map-icon {
  width: auto !important;
  height: auto !important;
}
.map-icon-user {
  width: auto !important;
  height: auto !important;
  z-index: 1000 !important;
}

.onTop {
  z-index: 1001 !important;
}

.hausBrandenburg {
  z-index: 999 !important;
}

.leaflet-pane svg {
  width: inherit;
  height: inherit;
  margin: inherit;
}

.leaflet-control {
  display: none;
}

img.leaflet-tile.leaflet-tile-loaded {
  min-width: 256px;
}

.controls {
  z-index: 999;
  display: flex;
  justify-content: flex-end;
}

.target {
  bottom: 1rem;
  z-index: 1000;
}

.noCategoryText {
  top: 90px !important;
}

//SMARTPHONE
@media screen and (max-width: 900px) {
  .map-page-container {
    display: block;
    width: 100%;
    height: calc(100% - 190px);
    top: 190px;
    overflow: hidden;
    position: fixed;
  }
  .target {
    bottom: calc(45px + 2rem);
    z-index: 1000;
  }
  .map {
    width: 100%;
    padding: 0rem;
    position: absolute;
  }
}

//DESKTOP
// @media screen and (min-width: 481px) {
//   .map {
//   min-height: 500px;
//   min-width: 500px;
//   }
// }
</style>
