<template>
  <section class="mt-10">
    <div
      class="p-5 bg-white mb-5 flex flex-align-center"
      v-for="(audio, index) in GetAudios"
      :key="index"
    >
      <audio :ref="'audio-' + index">
        <source :src="mainConfig.CMS_BASE_URL + audio.url" :type="audio.mime" />
        Ihr Browser unterstüzt leider kein Audio Element.
      </audio>
      <button
        class="play-pause-button bg-black hover:bg-grey mr-5"
        :class="{ mobileHoverBlack: this.$store.state.isMobile }"
        name="Play"
        @click="PlayPause(index)"
      >
        <PlayButton v-if="currentlyPlaying != index" />
        <StopButton v-if="currentlyPlaying == index" />
      </button>

      <div class="block flex-1">
        <div class="font-bold" :class="{ 'mb-2': currentlyPlaying == index }">
          {{
            audio.alternativeText == "" || audio.alternativeText == null
              ? "Alternativer Titel"
              : audio.alternativeText
          }}
        </div>
        <div
          class="flex flex-align-center w-full"
          v-if="currentlyPlaying != null && currentlyPlaying == index"
        >
          <div class="progress-bar w-full h-1 bg-gray-200 rounded-full mr-5">
            <div
              class="progress w-0 h-full bg-black rounded-full"
              :style="{ width: progressInfo.progress + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import mainConfig from "../main.config.js";
import PlayButton from "@/assets/Icons/Play.vue";
import StopButton from "@/assets/Icons/Stop.vue";
export default {
  name: "Audio",
  components: { PlayButton, StopButton },
  props: {
    poi: Object,
  },
  data() {
    return {
      mainConfig: mainConfig,
      currentlyPlaying: null,
      progressInfo: null,
    };
  },
  computed: {
    GetAudios() {
      if (this.poi.Audios.data == null) {
        return [];
      }

      return this.poi.Audios.data.map((audio) => {
        return audio.attributes;
      });
    },
  },
  beforeUnmount() {
    if (this.currentlyPlaying != null) {
      this.Stop();
    }
  },
  methods: {
    GetProgress() {
      var audio = this.$refs["audio-" + this.currentlyPlaying][0];

      var progress = (audio.currentTime / audio.duration) * 100;
      this.progressInfo = {
        duration: audio.duration,
        progress: progress,
      };
    },
    Stop() {
      if (this.currentlyPlaying == null) {
        return;
      }
      var audio = this.$refs["audio-" + this.currentlyPlaying][0];
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener("timeupdate", this.GetProgress);
      audio.removeEventListener("ended", this.Stop);
      audio.removeEventListener("pause", this.Stop);
      this.currentlyPlaying = null;
      this.progressInfo = null;
    },
    Play() {
      var audio = this.$refs["audio-" + this.currentlyPlaying][0];

      this.progressInfo = {
        duration: audio.duration,
        progress: 0,
      };
      audio.addEventListener("timeupdate", this.GetProgress);
      audio.addEventListener("ended", this.Stop);
      audio.addEventListener("pause", this.Stop);
      audio.play();
    },
    PlayPause(index) {
      if (this.currentlyPlaying != null && this.currentlyPlaying != index) {
        this.Stop();
      }
      this.currentlyPlaying = index;

      var audio = this.$refs["audio-" + index][0];

      if (audio.paused) {
        this.Play();
      } else {
        this.Stop();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.play-pause-button {
  width: 50px;
  height: 50px;
}
</style>
