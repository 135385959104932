<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 809.945 216.442"
		style="enable-background: new 0 0 809.945 216.442"
		xml:space="preserve"
	>
		<g>
			<path
				style="fill: #282982"
				d="M0,111.772h37.08V0H0v1.419C11.532,2.839,15.258,7.097,15.258,22v67.773
		c0,14.903-3.726,19.161-15.258,20.58V111.772L0,111.772z M95.45,82.144c0-19.516-17.209-27.145-36.725-29.629V52.16
		c15.967-2.839,28.741-12.242,28.741-25.548C87.466,5.677,65.289,0,47.015,0v1.419C58.37,2.484,66.531,8.161,66.531,26.79
		c0,11-5.5,20.935-14.371,24.129c-1.952,0.71-4.968,1.242-6.564,1.419v1.242c4.258,0.178,8.339,1.065,11,2.129
		c10.467,4.435,16.322,13.483,16.322,26.08c0,17.032-5.855,26.967-25.725,28.564v1.419c3.903,0,7.984-0.177,11.177-0.532
		C79.128,108.934,95.45,101.305,95.45,82.144L95.45,82.144z"
			/>
			<path
				style="fill: #282982"
				d="M141.542,216.442c4.998,0,7.301-3.43,8.085-4.851l0,0v4.165h4.508v-24.304h-4.606v10.584
		c0,3.773-0.294,10.388-6.37,10.388c-3.038,0-4.998-1.764-4.998-6.125v-14.847h-4.606v15.925
		C133.555,212.767,136.005,216.442,141.542,216.442L141.542,216.442z M165.806,215.756V205.27c0-3.43,0.098-10.486,6.468-10.486
		c3.136,0,5.145,1.764,5.145,6.125v14.847h4.606v-15.925c0-5.39-2.45-9.065-8.428-9.065c-5.096,0-7.448,3.577-7.938,4.753l0,0
		v-4.067H161.2v24.304H165.806L165.806,215.756z M197.484,216.442c4.263,0,6.664-2.352,7.84-4.361l0,0v3.675h4.508v-34.3h-4.606
		v9.016v4.704c-1.176-2.009-3.528-4.41-7.742-4.41c-5.733,0-9.996,4.9-9.996,12.887
		C187.488,211.591,191.751,216.442,197.484,216.442L197.484,216.442z M198.758,212.522c-3.724,0-6.517-2.254-6.517-8.869
		c0-6.566,2.793-8.967,6.517-8.967c3.92,0,6.713,2.989,6.713,8.967C205.471,209.631,202.678,212.522,198.758,212.522
		L198.758,212.522z M245.552,216.442c7.203,0,10.339-4.214,11.564-6.664l0,0v5.978h4.753v-16.905h-16.905v4.508h11.858
		c-0.294,4.116-3.969,8.624-10.584,8.624c-6.86,0-11.662-4.9-11.662-13.426c0-8.722,5.831-13.23,11.858-13.23
		c5.782,0,8.869,3.234,10.535,7.742l4.998-2.009c-1.764-5.194-6.713-10.29-15.435-10.29c-9.408,0-17.199,7.301-17.199,17.836
		C229.333,209.582,235.703,216.442,245.552,216.442L245.552,216.442z M278.647,216.442c5.047,0,8.918-2.352,10.682-7.007
		l-4.214-1.519c-0.686,1.715-2.401,4.655-6.321,4.655c-3.332,0-6.566-1.96-6.762-7.791h17.101
		c0.882-7.938-3.43-14.014-10.682-14.014c-6.321,0-11.025,4.998-11.025,12.936C267.426,211.885,271.885,216.442,278.647,216.442
		L278.647,216.442z M272.13,201.105c0.392-4.214,3.185-6.664,6.321-6.664c3.234,0,5.978,2.303,6.027,6.664H272.13L272.13,201.105z
		 M303.788,216.442c6.272,0,9.898-2.94,9.898-7.546c0-5.39-5.39-6.958-8.183-7.546l-2.891-0.539
		c-3.381-0.637-4.116-1.568-4.116-3.136c0-1.715,1.47-3.234,4.704-3.234c3.822,0,5.292,2.401,5.88,4.067l4.067-1.568
		c-0.98-3.087-3.871-6.174-9.898-6.174c-5.047,0-9.162,2.989-9.162,7.35c0,4.655,3.478,6.223,7.643,7.056l2.94,0.539
		c3.332,0.637,4.361,1.862,4.361,3.577c0,2.156-1.813,3.381-5.194,3.381c-3.969,0-5.733-2.107-6.37-4.606l-4.361,1.421
		C294.135,214.09,298.692,216.442,303.788,216.442L303.788,216.442z M329.185,216.442c5.488,0,9.163-3.185,10.78-7.693l-4.361-1.519
		c-0.833,2.695-2.646,5.243-6.468,5.243c-3.871,0-6.958-2.842-6.958-8.869c0-5.929,3.136-8.869,6.909-8.869
		c3.381,0,5.145,2.156,5.978,4.557l4.312-1.47c-1.225-3.822-4.704-7.056-10.241-7.056c-6.811,0-11.76,5.243-11.76,12.838
		C317.376,211.444,322.08,216.442,329.185,216.442L329.185,216.442z M349.323,215.756V205.27c0-3.43,0.098-10.486,6.468-10.486
		c3.136,0,5.145,1.764,5.145,6.125v14.847h4.606v-15.925c0-5.39-2.597-9.065-8.134-9.065s-7.644,3.577-8.085,4.753l0,0v-5.047
		v-9.016h-4.606v34.3H349.323L349.323,215.756z M377.086,186.944v-5.488h-4.9v5.488H377.086L377.086,186.944z M376.988,215.756
		v-24.304h-4.655v24.304H376.988L376.988,215.756z M394.211,216.442c5.488,0,9.163-3.185,10.78-7.693l-4.361-1.519
		c-0.833,2.695-2.646,5.243-6.468,5.243c-3.871,0-6.958-2.842-6.958-8.869c0-5.929,3.136-8.869,6.909-8.869
		c3.381,0,5.145,2.156,5.978,4.557l4.312-1.47c-1.225-3.822-4.704-7.056-10.241-7.056c-6.811,0-11.76,5.243-11.76,12.838
		C382.402,211.444,387.106,216.442,394.211,216.442L394.211,216.442z M414.35,215.756V205.27c0-3.43,0.098-10.486,6.468-10.486
		c3.136,0,5.145,1.764,5.145,6.125v14.847h4.606v-15.925c0-5.39-2.597-9.065-8.134-9.065s-7.644,3.577-8.085,4.753l0,0v-5.047
		v-9.016h-4.606v34.3H414.35L414.35,215.756z M451.927,215.756v-3.969h-5.096c-1.666,0-2.401-0.735-2.401-2.401v-14.112h6.566
		v-3.822h-6.566v-7.595h-4.606v7.595h-5.537v3.822h5.537v14.504c0,4.018,2.107,5.978,6.125,5.978H451.927L451.927,215.756z
		 M465.813,216.442c5.047,0,8.918-2.352,10.682-7.007l-4.214-1.519c-0.686,1.715-2.401,4.655-6.321,4.655
		c-3.332,0-6.566-1.96-6.762-7.791h17.101c0.882-7.938-3.43-14.014-10.682-14.014c-6.321,0-11.025,4.998-11.025,12.936
		C454.592,211.885,459.051,216.442,465.813,216.442L465.813,216.442z M459.296,201.105c0.392-4.214,3.185-6.664,6.321-6.664
		c3.234,0,5.978,2.303,6.027,6.664H459.296L459.296,201.105z"
			/>
			<path
				style="fill: #282982"
				d="M139.44,143.274h6.272v-3.822h-6.272v-3.626c0-1.666,0.686-2.401,2.401-2.401h4.753v-3.969h-5.635
		c-4.018,0-6.125,2.009-6.125,6.419v3.577h-5.292v3.822h5.292v20.482h4.606V143.274L139.44,143.274z M157.56,134.993v-5.047h-4.949
		v5.047H157.56L157.56,134.993z M167.164,134.993v-5.047h-4.9v5.047H167.164L167.164,134.993z M157.658,164.442
		c4.998,0,7.301-3.43,8.085-4.851l0,0v4.165h4.508v-24.304h-4.606v10.584c0,3.773-0.294,10.388-6.37,10.388
		c-3.038,0-4.998-1.764-4.998-6.125v-14.847h-4.606v15.925C149.671,160.767,152.121,164.442,157.658,164.442L157.658,164.442z
		 M181.922,163.756v-10.927c0-5.733,2.254-8.967,7.644-8.967c0.98,0,1.813,0.098,2.597,0.196v-4.704
		c-0.539-0.147-1.225-0.245-2.254-0.245c-4.851,0-7.252,3.626-8.085,5.488l0,0v-5.145h-4.508v24.304H181.922L181.922,163.756z
		 M235.135,163.756v-4.508H218.72h-5.537l21.119-24.745v-5.047h-25.48v4.508h14.161h5.586l-20.629,24.108v5.684H235.135
		L235.135,163.756z M247.581,164.442c4.998,0,7.301-3.43,8.085-4.851l0,0v4.165h4.508v-24.304h-4.606v10.584
		c0,3.773-0.294,10.388-6.37,10.388c-3.038,0-4.998-1.764-4.998-6.125v-14.847h-4.606v15.925
		C239.594,160.767,242.044,164.442,247.581,164.442L247.581,164.442z M291.152,163.756l-11.662-14.21l10.78-10.094h-6.027
		l-12.397,12.103v-8.085v-14.014h-4.606v34.3h4.606V156.7l4.41-4.116l8.967,11.172H291.152L291.152,163.756z M302.309,164.442
		c4.998,0,7.301-3.43,8.085-4.851l0,0v4.165h4.508v-24.304h-4.606v10.584c0,3.773-0.294,10.388-6.37,10.388
		c-3.038,0-4.998-1.764-4.998-6.125v-14.847h-4.606v15.925C294.322,160.767,296.772,164.442,302.309,164.442L302.309,164.442z
		 M326.573,163.756V153.27c0-3.43,0.098-10.486,6.468-10.486c3.136,0,5.145,1.764,5.145,6.125v14.847h4.606v-15.925
		c0-5.39-2.45-9.065-8.428-9.065c-5.096,0-7.448,3.577-7.938,4.753l0,0v-4.067h-4.459v24.304H326.573L326.573,163.756z
		 M356.453,143.274h6.272v-3.822h-6.272v-3.626c0-1.666,0.686-2.401,2.401-2.401h4.753v-3.969h-5.635
		c-4.018,0-6.125,2.009-6.125,6.419v3.577h-5.292v3.822h5.292v20.482h4.606V143.274L356.453,143.274z M382.986,163.756v-3.969
		h-5.096c-1.666,0-2.401-0.735-2.401-2.401v-14.112h6.566v-3.822h-6.566v-7.595h-4.606v7.595h-5.537v3.822h5.537v14.504
		c0,4.018,2.107,5.978,6.125,5.978H382.986L382.986,163.756z M389.84,170.616l3.822-6.86v-6.37h-6.223v6.37h3.333l-3.872,6.86
		H389.84L389.84,170.616z M428.843,164.442c7.203,0,10.339-4.214,11.564-6.664l0,0v5.978h4.753v-16.905h-16.905v4.508h11.858
		c-0.294,4.116-3.969,8.624-10.584,8.624c-6.86,0-11.662-4.9-11.662-13.426c0-8.722,5.831-13.23,11.858-13.23
		c5.782,0,8.869,3.234,10.535,7.742l4.998-2.009c-1.764-5.194-6.713-10.29-15.435-10.29c-9.408,0-17.199,7.301-17.199,17.836
		C412.624,157.582,418.994,164.442,428.843,164.442L428.843,164.442z M461.938,164.442c5.047,0,8.918-2.352,10.682-7.007
		l-4.214-1.519c-0.686,1.715-2.401,4.655-6.321,4.655c-3.332,0-6.566-1.96-6.762-7.791h17.101
		c0.882-7.938-3.43-14.014-10.682-14.014c-6.321,0-11.025,4.998-11.025,12.936C450.717,159.885,455.176,164.442,461.938,164.442
		L461.938,164.442z M455.421,149.105c0.392-4.214,3.185-6.664,6.321-6.664c3.234,0,5.978,2.303,6.027,6.664H455.421L455.421,149.105
		z M493.592,173.311c4.214,0,7.742-2.254,7.742-6.811c0-4.067-3.087-6.811-7.84-6.811h-10.535c-1.666,0-2.156-0.784-2.156-1.568
		c0-0.882,0.833-1.764,2.156-2.303c1.372,0.735,2.94,0.931,4.606,0.931c5.439,0,9.212-3.528,9.653-9.016l0.098-1.176
		c0.196-2.793,0.98-3.283,3.038-3.283h2.009v-3.822h-2.499c-2.499,0-3.577,1.519-4.018,3.626c-1.127-1.911-3.822-4.312-8.33-4.312
		c-5.684,0-9.8,3.92-9.8,9.065c0,2.891,1.274,5.096,2.695,6.37c-1.666,1.078-3.626,2.744-3.626,5.145
		c0,1.96,0.784,3.136,2.156,3.969c-1.421,0.882-3.136,2.646-3.136,5.096c0,2.842,1.715,4.9,6.468,4.9H493.592L493.592,173.311z
		 M487.565,153.123c-3.185,0-5.243-2.107-5.243-5.341c0-3.185,2.058-5.39,5.243-5.39s5.243,2.205,5.243,5.39
		C492.808,151.016,490.75,153.123,487.565,153.123L487.565,153.123z M483.547,169.391c-2.352,0-3.675-0.588-3.675-2.548
		c0-1.519,0.98-2.597,1.764-3.087h11.368c1.96,0,3.675,0.49,3.675,2.793c0,2.303-1.715,2.842-3.675,2.842H483.547L483.547,169.391z
		 M515.862,164.442c5.047,0,8.918-2.352,10.682-7.007l-4.214-1.519c-0.686,1.715-2.401,4.655-6.321,4.655
		c-3.332,0-6.566-1.96-6.762-7.791h17.101c0.882-7.938-3.43-14.014-10.682-14.014c-6.321,0-11.025,4.998-11.025,12.936
		C504.641,159.885,509.1,164.442,515.862,164.442L515.862,164.442z M509.345,149.105c0.392-4.214,3.185-6.664,6.321-6.664
		c3.234,0,5.978,2.303,6.027,6.664H509.345L509.345,149.105z M536.706,163.756V153.27c0-3.43,0.098-10.486,6.468-10.486
		c3.136,0,5.145,1.764,5.145,6.125v14.847h4.606v-15.925c0-5.39-2.45-9.065-8.428-9.065c-5.096,0-7.448,3.577-7.938,4.753l0,0
		v-4.067H532.1v24.304H536.706L536.706,163.756z M569.56,163.756l3.675-11.76c1.421-4.508,1.862-6.027,2.352-7.497l0,0l2.303,7.546
		l3.577,11.711h5.439l8.183-24.304h-4.851l-3.479,11.172c-1.519,5.047-2.156,7.056-2.548,8.183l0,0l-2.009-6.713l-3.822-12.642
		h-5.39l-3.528,11.564c-1.47,4.802-2.009,6.566-2.352,7.84l0,0l-2.45-8.085l-3.43-11.319h-4.802l7.938,24.304H569.56L569.56,163.756
		z M605.883,164.442c3.871,0,6.223-1.715,7.938-4.361c0.294,2.058,0.98,3.675,4.508,3.675h3.528v-3.92h-2.499
		c-1.127,0-1.421-0.294-1.421-1.421v-11.123c0-5.243-3.136-8.526-9.702-8.526c-6.419,0-9.898,3.479-10.682,6.811l4.41,1.421
		c0.637-1.862,2.009-4.361,6.272-4.361c3.577,0,5.096,1.715,5.096,4.508v0.735c-7.889,0.637-15.288,2.891-15.288,9.604
		C598.043,161.796,601.13,164.442,605.883,164.442L605.883,164.442z M607.108,160.669c-2.891,0-4.312-1.372-4.312-3.577
		c0-3.871,4.851-5.292,10.535-5.88v2.499C613.331,158.562,610.195,160.669,607.108,160.669L607.108,160.669z M630.427,163.756
		v-10.927c0-5.733,2.254-8.967,7.644-8.967c0.98,0,1.813,0.098,2.597,0.196v-4.704c-0.539-0.147-1.225-0.245-2.254-0.245
		c-4.851,0-7.252,3.626-8.085,5.488l0,0v-5.145h-4.508v24.304H630.427L630.427,163.756z M660.958,163.756v-3.969h-5.096
		c-1.666,0-2.401-0.735-2.401-2.401v-14.112h6.566v-3.822h-6.566v-7.595h-4.606v7.595h-5.537v3.822h5.537v14.504
		c0,4.018,2.107,5.978,6.125,5.978H660.958L660.958,163.756z"
			/>
			<path
				style="fill: #282982"
				d="M150.754,111.756c7.546,0,12.642-3.577,12.642-9.457c0-4.263-3.038-7.644-8.134-8.526
		c4.459-1.127,6.958-3.969,6.958-8.036c0-4.949-4.067-8.281-11.613-8.281h-17.052v34.3H150.754L150.754,111.756z M142.571,84.463
		h6.517c2.695,0,4.312,0.98,4.312,3.234c0,2.205-1.666,3.283-4.312,3.283h-6.517V84.463L142.571,84.463z M142.571,97.35h6.615
		c2.891,0,4.9,1.176,4.9,3.724c0,2.548-2.058,3.675-4.9,3.675h-6.615V97.35L142.571,97.35z M179.026,111.756V99.114h5.292
		c3.528,0,4.9,1.274,5.439,4.753l1.323,7.889h9.8l-1.617-7.938c-1.078-5.194-3.479-7.154-8.183-7.644
		c4.606-0.686,8.869-3.626,8.869-9.359c0-5.684-4.606-9.359-12.25-9.359h-17.836v34.3H179.026L179.026,111.756z M179.026,84.659
		h6.811c2.744,0,4.655,1.274,4.655,3.871s-1.96,3.969-4.655,3.969h-6.811V84.659L179.026,84.659z M240.864,111.756l-12.397-34.3
		h-12.25l-12.397,34.3h9.457l2.352-7.154h12.936l2.254,7.154H240.864L240.864,111.756z M219.794,91.911
		c1.225-3.626,1.813-5.635,2.401-7.595c0.539,1.96,1.127,3.871,2.303,7.546l1.813,5.684h-8.379L219.794,91.911L219.794,91.911z
		 M254.633,111.756V97.007v-10.29l16.219,25.039h9.212v-34.3h-8.967v11.466v8.771l-13.181-20.237h-12.25v34.3H254.633
		L254.633,111.756z M302.506,111.756c10.192,0,18.179-6.566,18.179-17.346c0-10.682-7.938-16.954-18.424-16.954h-13.916v34.3
		H302.506L302.506,111.756z M297.557,84.757h4.508c5.929,0,9.016,3.92,9.016,9.751c0,6.419-3.087,9.898-9.212,9.898h-4.312V84.757
		L297.557,84.757z M353.759,111.756v-7.301h-17.493v-6.811h13.524v-6.811h-13.524v-6.076h17.493v-7.301h-26.558v34.3H353.759
		L353.759,111.756z M369.635,111.756V97.007v-10.29l16.219,25.039h9.212v-34.3h-8.967v11.466v8.771l-13.181-20.237h-12.25v34.3
		H369.635L369.635,111.756z M420.545,111.756c7.546,0,12.642-3.577,12.642-9.457c0-4.263-3.038-7.644-8.134-8.526
		c4.459-1.127,6.958-3.969,6.958-8.036c0-4.949-4.067-8.281-11.613-8.281h-17.052v34.3H420.545L420.545,111.756z M412.362,84.463
		h6.517c2.695,0,4.312,0.98,4.312,3.234c0,2.205-1.666,3.283-4.312,3.283h-6.517V84.463L412.362,84.463z M412.362,97.35h6.615
		c2.891,0,4.9,1.176,4.9,3.724c0,2.548-2.058,3.675-4.9,3.675h-6.615V97.35L412.362,97.35z M455.237,112.589
		c9.8,0,15.974-5.096,15.974-14.798V77.456h-9.261v19.159c0,5.341-1.96,8.281-6.713,8.281c-4.557,0-6.713-2.793-6.713-8.281V77.456
		h-9.261v20.335C439.263,107.346,445.143,112.589,455.237,112.589L455.237,112.589z M488.311,111.756V99.114h5.292
		c3.528,0,4.9,1.274,5.439,4.753l1.323,7.889h9.8l-1.617-7.938c-1.078-5.194-3.479-7.154-8.183-7.644
		c4.606-0.686,8.869-3.626,8.869-9.359c0-5.684-4.606-9.359-12.25-9.359h-17.836v34.3H488.311L488.311,111.756z M488.311,84.659
		h6.811c2.744,0,4.655,1.274,4.655,3.871s-1.96,3.969-4.655,3.969h-6.811V84.659L488.311,84.659z M530.403,112.589
		c6.321,0,9.408-3.038,10.927-5.292l0,0v4.459h8.232v-18.13H531.04v6.811h9.457c-0.49,2.646-2.94,5.243-7.644,5.243
		c-4.998,0-8.82-3.675-8.82-10.78c0-6.86,3.626-10.878,8.869-10.878c4.606,0,6.811,2.842,7.889,6.125l9.016-3.185
		c-1.715-5.194-6.664-10.339-16.758-10.339c-11.319,0-18.669,7.938-18.669,18.179C514.38,106.513,521.73,112.589,530.403,112.589
		L530.403,112.589z M577.589,111.756V99.359V84.953l8.379,26.803h7.497l8.428-26.852v14.504v12.348h9.065v-34.3h-13.916
		l-4.312,13.181c-1.421,4.802-2.107,7.35-2.744,9.604c-0.588-2.156-1.274-4.802-2.744-9.604l-4.165-13.181h-14.504v34.3H577.589
		L577.589,111.756z M634.772,112.589c9.8,0,15.974-5.096,15.974-14.798V77.456h-9.261v19.159c0,5.341-1.96,8.281-6.713,8.281
		c-4.557,0-6.713-2.793-6.713-8.281V77.456h-9.261v20.335C618.798,107.346,624.678,112.589,634.772,112.589L634.772,112.589z
		 M672.551,112.589c10.388,0,15.533-4.753,15.533-11.074c0-6.223-4.851-9.8-12.446-10.731l-4.312-0.539
		c-2.597-0.343-4.018-1.421-4.018-3.234c0-1.862,1.372-3.528,4.949-3.528c3.626,0,5.635,2.107,6.468,4.851l9.016-2.303
		c-1.372-5.292-6.321-9.408-15.288-9.408c-9.212,0-14.504,4.802-14.504,10.731c0,6.419,3.969,9.506,11.515,10.486l4.263,0.539
		c3.381,0.441,4.655,1.764,4.655,3.577c0,2.107-1.617,3.724-5.733,3.724c-4.508,0-6.762-2.891-7.35-6.027l-9.065,2.352
		C657.459,107.934,662.653,112.589,672.551,112.589L672.551,112.589z M721.158,111.756v-7.301h-17.493v-6.811h13.524v-6.811h-13.524
		v-6.076h17.493v-7.301H694.6v34.3H721.158L721.158,111.756z M743.649,112.589c9.8,0,15.974-5.096,15.974-14.798V77.456h-9.261
		v19.159c0,5.341-1.96,8.281-6.713,8.281c-4.557,0-6.713-2.793-6.713-8.281V77.456h-9.261v20.335
		C727.675,107.346,733.555,112.589,743.649,112.589L743.649,112.589z M776.576,111.756V99.359V84.953l8.379,26.803h7.497
		l8.428-26.852v14.504v12.348h9.065v-34.3h-13.916l-4.312,13.181c-1.421,4.802-2.107,7.35-2.744,9.604
		c-0.588-2.156-1.274-4.802-2.744-9.604l-4.165-13.181H767.56v34.3H776.576L776.576,111.756z"
			/>
		</g>
	</svg>
</template>
