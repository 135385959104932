<template>
  <div
    class="flex w-full h-full flex-column flex-center text-center"
    v-if="landingPageData != null"
  >
    <div
      :style="{
        backgroundImage:
          'url(' + mainConfig.CMS_BASE_URL + GetBackgroundImage + ')',
      }"
      class="background fixed top-0 left-0 bottom-0 right-0 w-full h-full bg-center bg-cover bg-no-repeat"
    ></div>

    <article
      class="text-right w-2/3 min-w-min max-w-4xl m-auto text-left landing-text-container"
      :class="{ scaleText: $store.state.ausstellung }"
    >
      <header class="text-left">
        <h1>{{ landingPageData.Title }}</h1>
        <h2>{{ landingPageData.Subtitle }}</h2>
      </header>
      <VMarkdownView
        class="description pl-7 text-left"
        :content="
          this.$store.state.ausstellung
            ? landingPageData.Description_ausstellung
            : landingPageData.Description
        "
      />
      <div class="lg:pl-7 mt-10 button-container">
        <router-link
          class="nav-link bg-black hover:bg-grey uppercase text-white flex items-center"
          :class="{ mobileHoverBlack: this.$store.state.isMobile }"
          :to="{ name: 'Map' }"
          ><span class="ml-3">Interaktive Karte</span><ArrowRight class="icon"
        /></router-link>
      </div>
    </article>
  </div>
</template>

<script>
import mainConfig from "../main.config.js";
import { VMarkdownView } from "vue3-markdown";
import { GET } from "../services/IO.ts";
import ArrowRight from "@/assets/Icons/ArrowRight.vue";
import DMXService from "../services/DMXService.js";

export default {
  name: "LandingPage",
  components: {
    VMarkdownView,
    ArrowRight,
  },
  data() {
    return {
      landingPageData: null,
      mainConfig: mainConfig,
    };
  },
  computed: {
    GetBackgroundImage() {
      let url = this.landingPageData.BackgroundImage.data.attributes.url;

      if (
        Object.prototype.hasOwnProperty.call(
          this.landingPageData.BackgroundImage.data.attributes.formats,
          "medium"
        )
      ) {
        url =
          this.landingPageData.BackgroundImage.data.attributes.formats.medium
            .url;
      }
      if (
        Object.prototype.hasOwnProperty.call(
          this.landingPageData.BackgroundImage.data.attributes.formats,
          "large"
        )
      ) {
        url =
          this.landingPageData.BackgroundImage.data.attributes.formats.large
            .url;
      }
      return url;
    },
  },
  mounted() {
    GET("/landing-page?_locale=de&populate=*")
      .then((response) => {
        this.landingPageData = response.data.data.attributes;
      })
      .catch((error) => {
        console.log("error while loading data:", error);
      });

    this.Reset();

    DMXService.SendDMXMessage({
      channels: [50],
      color: "#28348e",
    });
  },

  methods: {
    Reset() {
      this.$store.commit("SetCategory", "All");
      this.$store.commit("SetPreselectedPOI", null);
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  @include highlight;
}
.background {
  z-index: -1;
  opacity: 0.4;
}

.nav-link {
  span {
    line-height: 30px;
    vertical-align: middle;
  }
}

.scaleText {
  transform: scale(1.8);
}

.landing-text-container {
  width: 55%;
  max-width: 600px;
}
.button-container {
  width: fit-content;
}
@media screen and (max-width: 900px) {
  .landing-text-container {
    width: 100%;
    padding-left: $header-height;
    padding-right: $header-height;
  }
}
</style>
