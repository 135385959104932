<template>
  <router-link
    class="nav-button bg-white info-icon hover:bg-grey"
    :class="{
      mobileHoverWhite: this.$store.state.isMobile,
      arView: this.$route.name == 'ARViewer',
    }"
    :to="{ name: 'ImpressumPage' }"
  >
    <Info
  /></router-link>
</template>

<script>
import Info from "../assets/Icons/Info.vue";
export default {
  name: "InfoIcon",
  components: { Info },
};
</script>
<style scoped>
.info-icon {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1003;
  padding: 0.5rem;
}

.arView {
  top: 1rem !important;
  bottom: auto !important;
}

@media screen and (max-width: 900px) {
  .info-icon {
    top: auto;
    bottom: 1rem;
    right: 0px;
    position: fixed;
  }
}
</style>