<template>
  <div class="user-position"></div>
</template>

<script>
import router from "../router";
import store from "../store/store";

export default {
  name: "UserMapMarker",
  props: [],
  data() {
    return {};
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.user-position {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #2d2d82;
  border: 2px solid #fff;
  z-index: 1000;
  position: absolute;
  top: -10px;
  left: -10px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
  cursor: default;
}

.radius {
  position: absolute;
}
</style>