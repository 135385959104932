const DMXService = {
	SendDMXMessage(dmxMessage) {
		var resetMsg = {
            channels: [...Array(512).keys()],
            value: { r: 0, g: 0, b:0 },
          }
		  console.log("DMX Message ",resetMsg)
		  if (window.dmx) {
			  window.dmx.send(resetMsg);
		  }



		setTimeout(()=>{
				var rgb = this.hexToRgb(dmxMessage.color);
				var msg = {
					channels: dmxMessage.channels,
					value: { r: rgb.r, g: rgb.g, b: rgb.b },
				}
				console.log("DMX Message ",msg)
				if (window.dmx) {
					window.dmx.send(msg);
				}
		}, 250)

		
	},
	Receive() {
		if (window.dmx) {
			window.dmx.receive((json) => console.log(json));
		}
	},
	hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
		  r: parseInt(result[1], 16),
		  g: parseInt(result[2], 16),
		  b: parseInt(result[3], 16)
		} : null;
	  }
};

export default DMXService;
