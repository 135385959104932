<template>
  <div
    v-if="
      this.impressumData != null &&
      kontaktData != null &&
      datenschutzData != null
    "
    class="impressum-container flex w-full flex-column flex-center text-center"
  >
    <div class="links fixed shadow-bottom">
      <a @click.prevent="smoothScroll" href="#anchor-impressum" class="pr-1"
        >Über
      </a>
      |
      <a @click.prevent="smoothScroll" href="#anchor-kontakt" class="pr-1 pl-1">
        Impressum
      </a>
      |
      <a @click.prevent="smoothScroll" href="#anchor-datenschutz" class="pl-1">
        Datenschutz</a
      >
    </div>

    <div
      @scroll="this.handleScroll"
      ref="container"
      class="text-container pt-10 pb-10"
    >
      <a class="anchor-offset" id="anchor-impressum"></a>
      <article class="text-right w-4/5 max-w-4xl m-auto text-left mt-10">
        <header class="text-left">
          <h1>{{ impressumData.Title }}</h1>
        </header>
        <VMarkdownView
          class="description text-left"
          :content="impressumData.Text"
        />
      </article>

      <a class="anchor-offset" id="anchor-kontakt"></a>
      <article class="text-right w-4/5 max-w-4xl m-auto text-left mt-10">
        <header class="text-left">
          <h1>{{ kontaktData.Title }}</h1>
        </header>
        <VMarkdownView
          class="description text-left"
          :content="kontaktData.Text"
        />
      </article>

      <a class="anchor-offset" id="anchor-datenschutz"></a>
      <article class="text-right w-4/5 max-w-4xl m-auto text-left mt-10">
        <header class="text-left">
          <h1>{{ datenschutzData.Title }}</h1>
        </header>
        <VMarkdownView
          class="description text-left"
          :content="datenschutzData.Text"
        />
      </article>
    </div>
  </div>
</template>

<script>
import { GET } from "../services/IO.ts";
import { VMarkdownView } from "vue3-markdown";
import ScrollMixin from "../components/ScrollMixin";
export default {
  name: "ImpressumPage",
  components: { VMarkdownView },
  mixins: [ScrollMixin],
  data() {
    return {
      impressumData: null,
      kontaktData: null,
      datenschutzData: null,
    };
  },
  mounted() {
    GET("/impressum?_locale=de&populate=*")
      .then((response) => {
        this.impressumData = response.data.data.attributes;
      })
      .catch((error) => {
        console.log("error while loading data:", error);
      });
    GET("/kontakt?_locale=de&populate=*")
      .then((response) => {
        this.kontaktData = response.data.data.attributes;
      })
      .catch((error) => {
        console.log("error while loading data:", error);
      });
    GET("/datenschutz?_locale=de&populate=*")
      .then((response) => {
        this.datenschutzData = response.data.data.attributes;
      })
      .catch((error) => {
        console.log("error while loading data:", error);
      });
  },
  methods: {
    smoothScroll(e) {
      console.log(e.target.getAttribute("href"));

      const target = e.target.getAttribute("href");
      const container = this.$refs.container;
      const offset = document.querySelector(target).offsetTop;
      container.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding-bottom: 1rem;
}
::v-deep h3 {
  padding-bottom: 0.2rem;
  font-weight: bold;
}

::v-deep h4 {
  font-weight: bold;
}

::v-deep p {
  padding-bottom: 0.6rem;
}

::v-deep a {
  color: #2d2d82;
}

.pr-1 {
  color: black !important;
}
.pl-1 {
  color: black !important;
}

.impressum-container {
  background-image: url("../assets/HBPG_Paper_Background.jpg");
  position: fixed;
  top: $header-height;
  height: calc(100% - 45px);
}
.anchor-offset {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}
.links {
  z-index: 1000;
  position: fixed;
  top: $header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $header-height;
  background-image: url("../assets/HBPG_Paper_Background.jpg");
}
.text-container {
  overflow-y: auto;
}
</style>
