<template>
  <nav
    v-if="
      (!this.$store.state.isMobile && this.$route.name != 'ARViewer') ||
      (this.$route.name != 'Landing' && this.$route.name != 'ARViewer')
    "
    class="shadow-bottom fixed w-full top-0 flex items-center"
    id="nav"
  >
    <div v-if="this.GetBackPath() != null && this.$route.name != 'Landing'">
      <router-link
        class="nav-button bg-black hover:bg-grey"
        :class="{
          mobileHoverBlack:
            this.$store.state.isMobile || this.$store.state.ausstellung,
        }"
        :to="this.GetBackPath()"
        ><ArrowLeft
      /></router-link>
    </div>

    <div>
      <span v-if="this.$store.state.isMobile">Eine Frage der Perspektive</span>
    </div>

    <!-- 
    <router-link to="/ar-viewer">AR Viewer</router-link>
    {{ mainConfig.development ? "|" : "" }}
    <router-link to="/dmx" v-if="mainConfig.development">DMX</router-link> -->

    <div class="sidebar-btns">
      <button
        v-if="
          !this.$store.state.sidebarOpen &&
          this.$route.path != '/impressum' &&
          this.$route.path != '/ar-viewer' &&
          this.$store.state.viewMode != 'AR'
        "
        class="nav-button bg-black hover:bg-grey"
        :class="{ mobileHoverBlack: this.$store.state.isMobile }"
        @click="OpenList(true)"
      >
        <List />
      </button>
      <button
        v-if="
          this.$store.state.sidebarOpen &&
          this.$route.path != '/impressum' &&
          this.$route.path != '/ar-viewer' &&
          this.$store.state.viewMode != 'AR' &&
          this.$store.state.isMobile
        "
        class="nav-button bg-black hover:bg-grey"
        :class="{ mobileHoverBlack: this.$store.state.isMobile }"
        @click="OpenList(false)"
      >
        <Exit />
      </button>
    </div>
  </nav>
</template>
<script>
import mainConfig from "../main.config";
import ArrowLeft from "../assets/Icons/ArrowLeft.vue";
import Exit from "../assets/Icons/Exit.vue";
import List from "../assets/Icons/List.vue";
export default {
  name: "Navigation",
  components: { ArrowLeft, Exit, List },
  data() {
    return {
      mainConfig: mainConfig,
    };
  },
  watch: {
    $route() {
      if (
        this.$route.params.id != undefined &&
        this.$route.params.id != null &&
        this.$route.params.id != ""
      ) {
        this.$store.commit("SetSidebar", false);
      } else {
        if (this.$store.state.isMobile) {
          return;
        }
        this.$store.commit("SetSidebar", true);
      }
    },
  },
  methods: {
    GetBackPath() {
      //FROM SINGLE POI TO ARVIEWER
      // if (this.$store.state.viewMode == "AR") {
      //   return { name: "ARViewer" };
      // }

      //FROM SINGLE POI TO MAP
      if (this.$route.params.id != "" && this.$route.name == "Map") {
        console.log("BACK PATH ", this.$router.options.history.state.back);
        if (this.$router.options.history.state.back == "/ar-viewer") {
          return { name: "ARViewer" };
        }
        return { name: "Map" };
      }

      //FROM MAP TO LANDING
      else if (
        (this.$route.params.id == null || this.$route.params.id == "") &&
        this.$route.name == "Map"
      ) {
        return { name: "Landing" };
      }

      if (this.$router.options.history.state.back == null) {
        return { name: "Landing" };
      }

      //FROM IMPRESSUM
      return { path: this.$router.options.history.state.back };
    },
    OpenList(state) {
      if (state) {
        this.$router.push({ name: "Map", params: { id: null } });
      }
      this.$store.commit("SetSidebar", state);
    },
  },
};
</script>
<style lang="scss" scoped>
#nav {
  height: $header-height;
  background-color: $grey;
  z-index: 1003;
  flex-direction: row;
  justify-content: space-between;
  background-image: url("../assets/HBPG_Paper_Background.jpg");
}
.sidebar-btns {
  margin-right: $header-height;
  width: $header-height;
}
span {
  font-size: 10px;
}
@media screen and (max-width: 900px) {
  .sidebar-btns {
    margin-right: 0px;
    display: block;
  }
}
</style>
