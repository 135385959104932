export default {
    data() {
        return {
          isScrolling: null,
        };
      },
    methods: {
        handleScroll(e) {
            if (this.isScrolling != null) {
              clearTimeout(this.isScrolling);
            }
      
            this.isScrolling = setTimeout(() => {
              e.target.classList.remove("on-scrollbar");
            }, 500);
      
            if (e.target.classList.contains("on-scrollbar") === false) {
              e.target.classList.add("on-scrollbar");
            }
          },
    }
}