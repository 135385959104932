<template>
  <div
    class="poi-marker"
    :class="{
      activePoi: this.isActive,
      isPreselected: this.isSelected,
      ausstellung: this.$store.state.ausstellung,
    }"
    v-if="this.IsVisible"
    ref="poiMarkerInner"
  >
    <div
      @click="this.OnMarkerTextClicked()"
      v-if="!this.isSelected"
      class="poiMarkerPointClick"
    >
      <div
        class="poiMarkerPointBig bg-black hover:bg-grey"
        :class="{
          hausBrandenburg: this.poiData.id == 34,
          mobileHoverBlack: this.$store.state.isMobile,
          poiMarkerPointSmall: this.$store.state.currentZoom <= 15,
        }"
      ></div>
    </div>

    <div
      class="text-container absolute right-0 flex flex-rowitems-center"
      :class="{
        textContainerOpen: this.isSelected,
        textContainerClosed: !this.isSelected,
      }"
    >
      <router-link
        class="flex h-full items-center bg-white poiTextClosed"
        :class="{ poiTextOpen: this.isSelected }"
        :to="{ name: 'Map' }"
        @click="ClosePOI()"
      >
        <!-- <div class="bg-yellow w-4 h-full"></div> -->
        <div class="pl-5 pr-4 text-black not-italic">
          <VMarkdownView
            class="poiMarkerTitle"
            :content="poiData.attributes.Title"
          />
        </div>
      </router-link>

      <div
        class="bg-black z-10 buttonClosed"
        :class="{
          buttonOpen: this.$route.params.id == this.poiData.id,
        }"
      >
        <!-- <ArrowRight /> -->
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRight from "../assets/Icons/ArrowRight.vue";
import { VMarkdownView } from "vue3-markdown";
export default {
  name: "POIMarker",
  props: ["poiData"],
  components: {
    //ArrowRight
    VMarkdownView,
  },
  data() {
    return {
      isSelected: false,
    };
  },
  mounted() {
    this.SetSelected();
  },
  unmounted() {},
  watch: {
    $route() {
      this.SetSelected();
    },
    "$store.state.currentZoom": function () {},
  },
  computed: {
    IsVisible() {
      var cat = Object.values(this.poiData.attributes.categories.data).find(
        (cat) => {
          return cat.attributes.Name == this.$store.state.currentCategory;
        }
      );

      if (
        (this.$store.state.currentCategory != "All" && cat == null) ||
        undefined
      ) {
        return false;
      }

      return true;
    },
    GetCategoryColor() {
      if (this.$store.state.currentCategory == null) {
        return this.poiData.attributes.categories.data[0].attributes
          .ColorString;
      }

      let cat = this.$store.state.categories.find(
        (cat) => cat.attributes.Name == this.$store.state.currentCategory
      );

      if (cat) {
        return cat.attributes.ColorString;
      }

      return "#fff";
    },
    isActive() {
      return this.poiData.id == this.$router.currentRoute._value.query.id;
    },
  },

  methods: {
    ClosePOI() {
      this.$store.commit("SetPreselectedPOI", null);
    },
    SetSelected() {
      var newSelected = this.$route.params.id == this.poiData.id;

      if (this.isSelected != newSelected) {
        this.isSelected = newSelected;
        if (this.isSelected) {
          this.$refs.poiMarkerInner.parentNode.parentNode.classList.add(
            "onTop"
          );
          this.$store.commit("SetPreselectedPOI", this.poiData);
        } else {
          this.$refs.poiMarkerInner?.parentNode?.parentNode?.classList.remove(
            "onTop"
          );
        }
      }
    },
    OnMarkerTextClicked() {
      this.$router.push({ name: "Map", params: { id: this.poiData.id } });
    },
    OnMarkerPointClicked() {
      this.$store.commit("SetPreselectedPOI", this.poiData);

      if (
        this.$route.params.id != null &&
        this.poiData.id != this.$route.params.id
      ) {
        this.$router.push({ name: "Map", params: { id: null } });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.text-container {
  height: 45px;
  z-index: 2;
  right: -25px;
  //top: -10px;
  cursor: auto;
}

.textContainerOpen {
  right: -35px;
  top: -12px;
  transition: right 0.2s, top 0.2s;
  pointer-events: all;
}

.textContainerClosed {
  pointer-events: none;
}

span {
  white-space: nowrap;
  padding-left: 0.6rem;
  padding-right: 0.5rem;
  margin-left: -0.4rem;
}

.poi-marker {
  display: flex;
  span {
    display: none;
  }
}

.poiMarkerPointBig {
  width: 20px;
  height: 20px;
}
.poiMarkerPointSmall {
  width: 10px;
  height: 10px;
}

.buttonClosed {
  min-width: 25px;
  min-height: 25px;
  opacity: 0;
}

.poiMarkerPointClick {
  width: 20px;
  height: 20px;
  z-index: 3;
}

.buttonOpen {
  min-width: 45px;
  min-height: 45px;
  opacity: 1;
  transition: min-width 0.2s, min-height 0.2s;
}
.buttonVisible {
  opacity: 0;
}

.activePoi {
  div {
    border: 4px solid #000;
  }

  span {
    display: block;
  }
}

.poiTextClosed {
  max-width: 0px;
  position: relative;
  right: -$header-height;
  opacity: 0;
}

.poiTextOpen {
  display: flex;
  width: max-content;
  opacity: 1;
  max-width: 1000px;
  right: 0px;
  position: relative;
  transition: max-width 0s, right 0.2s ease-in 0.1s, opacity 0.1s linear 0.1s;
}

.arrow-btn {
  top: 0px;
  right: -45px;
}

.isPreselected {
  span {
    display: block;
  }
}

.hausBrandenburg {
  width: 30px;
  height: 30px;
  background-image: url("../assets/BKG_HBPG_Logo.jpg");
  background-size: 30px;
}

span {
  font-size: 15px;
}
</style>
