<template>
  <svg viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="73" height="73" fill="white" />
    <path
      d="M37.3121 59.2219C25.3077 59.2219 15.4121 49.4885 15.4121 37.3219C15.4121 25.3174 25.1454 15.4219 37.3121 15.4219C49.4788 15.4219 59.2121 25.1552 59.2121 37.3219C59.2121 49.3263 49.3166 59.2219 37.3121 59.2219Z"
      fill="white"
    />
    <path
      d="M37.3118 17.851C48.0185 17.851 56.7785 26.611 56.7785 37.3177C56.7785 48.0244 48.0185 56.7844 37.3118 56.7844C26.6052 56.7844 17.8452 48.0244 17.8452 37.3177C17.8452 26.611 26.6052 17.851 37.3118 17.851ZM37.3118 12.9844C23.8474 12.9844 12.9785 23.8533 12.9785 37.3177C12.9785 50.7822 23.8474 61.651 37.3118 61.651C44.5565 61.651 61.6452 61.651 61.6452 61.651C61.6452 61.651 61.6452 43.5375 61.6452 37.3177C61.6452 23.8533 50.7763 12.9844 37.3118 12.9844Z"
      fill="#1E1E1D"
    />
    <path
      d="M34.877 47.1689V29H39.4192V47.3311H34.877V47.1689Z"
      fill="#1E1E1D"
    />
    <path
      d="M46.1513 40.4375L27.9824 40.4375L27.9824 35.8953L46.3135 35.8953L46.3135 40.4375L46.1513 40.4375Z"
      fill="#1E1E1D"
    />
  </svg>
</template>
