<template>
  <div id="singlePOI">
    <div
      @scroll="this.handleScroll"
      class="absolute bg-gray-100 overflow-x-hidden overflow-y-auto w-screen poi Home"
      :class="{
        poiVisible: this.poiData != null,
        Open: this.isOpen || this.$store.state.viewMode == 'AR',
        Close: !this.isOpen && this.$store.state.viewMode != 'AR',
      }"
      id="singlePOI-inner"
    >
      <article class="singlePOI shadow-top flex" v-if="poiData != null">
        <section class="w-full image-section">
          <Gallery :poi="poiData" :key="guid" />
          <Audio :poi="poiData" />
        </section>

        <section
          class="text-section"
          :class="{ ausstellung: this.$store.state.ausstellung }"
        >
          <header class="relative">
            <div class="relative z-10">
              <!-- <h1 class="text-black text-4xl font-bold pb-4 headlinePOI">
                {{ poiData.Title }}
              </h1> -->
              <VMarkdownView
                class="text-black text-4xl font-bold pb-4 headlinePOI"
                :content="poiData.Title"
              />
              <!-- <h2 class="text-black text-l font-medium mb-4">
                {{ poiData.Subtitle }}
              </h2> -->
            </div>
          </header>
          <section class="">
            <p class="text-black text-base mb-4 text-left">
              {{ poiData.Description }}
            </p>
          </section>

          <!-- <section v-if="GetRelatedPOIs.length > 0" class="teaser-section mb-4">
            <h3 class="font-bold">Weitere aus der selben Kategorie:</h3>
            <div class="flex flex-col">
              <SinglePOIOverview
                :poiData="poi"
                v-for="poi in GetRelatedPOIs"
                :key="poi.id"
              />
            </div>
          </section> -->
        </section>
      </article>
    </div>
  </div>
</template>
<script>
import mainConfig from "../main.config";
import Gallery from "./Gallery.vue";
import Audio from "./Audio.vue";
import ArrowLeft from "@/assets/Icons/ArrowLeft.vue";

import DMXService from "../services/DMXService.js";
import Swipe from "./Swipe.js";
import SinglePOIOverview from "./SinglePOIOverview.vue";
import ScrollMixin from "./ScrollMixin";
import { VMarkdownView } from "vue3-markdown";
export default {
  name: "Single POI Page",
  components: { Gallery, Audio, VMarkdownView },
  mixins: [ScrollMixin],
  data() {
    return {
      poiData: null,
      mainConfig: mainConfig,
      isOpen: false,
      guid: null,
      swiper: null,
    };
  },
  mounted() {
    this.GetPOIData();

    this.guid = this.CreateGuid();
  },
  unmounted() {
    this.$store.commit("ShowFullScreen", false);
  },

  watch: {
    $route() {
      this.GetPOIData();
    },
    "$store.state.pois": function () {
      this.GetPOIData();
    },
    poiData: function () {
      this.SetUpSwiper();
    },
  },
  computed: {
    GetRelatedPOIs() {
      if (
        this.$store.state.pois == null ||
        this.$store.state.pois.length == 0 ||
        this.poiData == null
      ) {
        return [];
      }

      let currentCategory = this.GetCurrentCategory();

      if (currentCategory == null || this.$store.state.pois.length == 0) {
        return [];
      }

      if (currentCategory == "All") {
        currentCategory =
          this.poiData.categories.data[0] != undefined
            ? this.poiData.categories.data[0].attributes.Name
            : "All";
      }

      return this.$store.state.pois
        .filter((poi) => {
          if (
            poi.attributes.categories.data == null ||
            poi.attributes.categories.data.length == 0
          ) {
            return false;
          }

          let poiCategories = poi.attributes.categories.data.map((category) => {
            return category.attributes.Name;
          });

          if (
            poiCategories.includes(currentCategory) &&
            poi.id != this.$route.params.id
          ) {
            return true;
          }
        })
        .slice(0, 3);
    },
  },
  methods: {
    CreateGuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    SetUpSwiper() {
      if (!this.$store.state.isMobile || this.poiData == null) {
        return;
      }

      this.swiper = new Swipe(document.getElementById("singlePOI"));
      this.swiper.run();

      this.swiper.onUp(() => {
        this.isOpen = true;
      });
      document.getElementById("singlePOI-inner").scrollTop = 0;

      this.swiper.onDown(() => {
        if (document.getElementById("singlePOI-inner").scrollTop != 0) {
          return;
        }
        this.isOpen = false;
      });
    },
    GetCurrentCategory() {
      if (this.$store.state.currentCategory != null) {
        return this.$store.state.currentCategory;
      }

      if (
        this.poiData != null &&
        this.poiData.categories != null &&
        this.poiData.categories.data != null &&
        this.poiData.categories.data.length > 0
      ) {
        return this.poiData.categories.data[0].attributes.Name;
      }

      return null;
    },
    GetPOIData() {
      this.guid = this.CreateGuid();

      var foundPOI = this.$store.state.pois.find((poi) => {
        return poi.id == this.$route.params.id;
      });

      if (foundPOI) {
        this.poiData = foundPOI.attributes;

        if (this.poiData.DMX_IDs == null) {
          return;
        }
        var dmx_ids = this.poiData.DMX_IDs.split(",").map(Number);

        var cat = this.$store.state.categories.find(
          (cat) => cat.attributes.Name == this.$store.state.currentCategory
        );

        var color = cat == null ? "#195b03" : cat.attributes.ColorString;

        DMXService.SendDMXMessage({
          channels: dmx_ids,
          color: color,
          //value: { r: 255, g: 255, b: 255 },
        });
      } else {
        this.poiData = null;
      }
      this.isOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.poi {
  z-index: 1002;
  height: calc(100% - 45px);
  width: 100%;
  top: $header-height;
  position: fixed;
  background-image: url("../assets/HBPG_Paper_Background.jpg");
  overflow: hidden;
  right: -100%;
  transition: right 0.2s;
}

.poiVisible {
  right: 0px !important;
  transition: right 1s;
}

.Home {
  width: 50%;
  right: -50%;
  overflow-y: auto;
  transition: right 0.5s;
  .text-section {
    padding-left: 0rem;
  }
  .singlePOI {
    flex-direction: column;
  }
  .image-section {
    width: 100%;
  }
  .teaser-section {
    padding-top: 2rem;
    padding-left: 0px;
  }
}

.singlePOI {
  flex-direction: row;
  padding-top: 4rem;
  padding-right: $header-height;
  padding-left: $header-height;
}
.text-section {
  padding-left: $header-height;
}
.teaser-section {
  padding-top: 5rem;
}

.image-section {
  width: 50%;
}

.headlinePOI {
}

@media all and (max-width: 1200px) {
  .Home {
    width: 600px;
    right: -600px;
  }
}

//SMARTPHONE
@media screen and (max-width: 900px) {
  .Home {
    width: 100%;
  }
  .poi {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: calc(100% - 45px);
    transition: top 1s;
    right: -100%;
  }
  .image-section {
    width: 100%;
  }

  .singlePOI {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .text-section {
    padding-left: 2rem !important;
    padding-right: 2rem;
  }
  teaser-section {
    padding-left: 0rem;
  }
  .Open {
    top: $header-height;
    overflow-y: auto;
  }
  .Close {
    top: 60%;
    overflow: hidden;
  }
}
</style>
