<template>
  <div
    class="bg-white hover:bg-grey mb-2 flex flex-row items-center teaser-container"
    :class="{
      mobileHoverWhite: this.$store.state.isMobile,
      ausstellung: this.$store.state.ausstellung,
    }"
  >
    <!-- <div class="h-full w-4 bg-yellow"></div> -->
    <router-link
      class="w-full pl-4 relative"
      :to="{
        name: 'Map',
        params: { id: poiData.id },
        query: { category: $route.query.category },
      }"
    >
      <!-- <div class="absolute h-full w-6 bg-yellow left-0 top-0"></div> -->
      <VMarkdownView :content="poiData.attributes.Title" />

      <!-- <h2 class="text-l pb-0">{{ poi.attributes.Subtitle }}</h2> -->
    </router-link>
  </div>
</template>
<script>
import mainConfig from "../main.config";
import { VMarkdownView } from "vue3-markdown";
export default {
  name: "Single POI Overview",
  props: ["poiData"],
  components: { VMarkdownView },
  data() {
    return {
      mainConfig: mainConfig,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.teaser-container {
  height: $header-height;
}
</style>
