<template>
  <v-idle
    v-if="this.$store.state.ausstellung"
    @idle="onidle"
    :loop="true"
    :duration="600"
    class="idle"
  />
  <Navigation />
  <router-view class="main" />

  <InfoIcon v-if="this.$route.path != '/impressum'" />
  <a
    v-if="!this.$store.state.ausstellung"
    href="https://gesellschaft-kultur-geschichte.de/haus-der-brandenburgisch-preussischen-geschichte/"
    class="absolute left-4 bottom-4"
    target="_blank"
  >
    <Logo v-if="this.GetLogoVisible" class="h-10 w-auto logo" />
  </a>

  <router-link
    v-if="this.$store.state.ausstellung"
    to="/"
    class="absolute left-4 bottom-4"
  >
    <Logo v-if="this.GetLogoVisible" class="h-10 w-auto logo" />
  </router-link>

  <section
    class="w-full h-full absolute gallery"
    :class="{ 'gallery-fullscreen': this.$store.state.showFullScreen }"
    v-if="
      this.$store.state.showFullScreen &&
      this.$store.state.pois[this.$route.params.id] != undefined
    "
  >
    <div class="gallery-fullscreen-inner">
      <Gallery
        :poi="
          this.$store.state.pois.find((p) => p.id == this.$route.params.id)
            .attributes
        "
        :isFullscreen="true"
      />
    </div>
  </section>
</template>
<script>
import store from "./store/store";
import Navigation from "./components/Navigation.vue";
import InfoIcon from "./components/InfoIcon.vue";
import Logo from "./assets/Icons/Logo.vue";
import Gallery from "./components/Gallery.vue";

export default {
  name: "app",
  store,
  components: {
    Navigation,
    InfoIcon,
    Logo,
    Gallery,
  },
  mounted() {
    this.$store.dispatch("GetAllPOIs");
    this.$store.dispatch("GetAllCategories");
    window.addEventListener("resize", this.GetDimensions);
    this.GetDimensions();
  },

  computed: {
    GetLogoVisible() {
      var visible = true;

      if (this.$route.name == "ImpressumPage") {
        visible = false;
      }

      if (this.$store.state.isMobile) {
        if (this.$store.state.sidebarOpen) {
          visible = false;
        }
        if (this.$route.params.id != undefined && this.$route.params.id != "") {
          visible = false;
        }
      }
      return visible;
    },
  },

  methods: {
    onidle() {
      this.$router.push({ name: "Landing" });
    },
    GetDimensions() {
      var width = window.innerWidth;
      this.$store.commit("SetMobile", width <= 900);
      this.$store.commit("SetSidebar", width > 900);
    },
  },
};
</script>
<style>
@import "./styles/imports.scss";

.mindar-ui-overlay {
  pointer-events: none;
}

.a-canvas {
  pointer-events: none;
}

.idle {
  display: none;
}

.logo {
  z-index: 999;
}
</style>

<style scoped>
.gallery {
  z-index: 1100;
  background-image: url("./assets/HBPG_Paper_Background.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery.gallery-fullscreen {
  padding: 1rem;
  display: block;
}
.gallery-fullscreen-inner {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
</style>
