<template>
  <div class="justify-center pt-10 bg-blue-300 items-center h-screen">
    <h1>TestPage für DMX Integration</h1>

    <button
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      :class="{ mobileHoverBlack: this.$store.state.isMobile }"
      @click="DMXEvent"
    >
      Testevent für Electron
    </button>
  </div>
</template>

<script>
import DMXService from "../services/DMXService.js";

export default {
  name: "DMXTestPage",
  mounted() {
    DMXService.Receive();
  },
  methods: {
    DMXEvent() {
      DMXService.SendDMXMessage({
        channels: [0, 3, 6],
        value: { r: 255, g: 255, b: 255 },
      });
    },
  },
};
</script>
