<template>
  <div
    class="overview-container"
    :class="{ ausstellung: this.$store.state.ausstellung }"
  >
    <div class="filter-container-outer shadow-bottom">
      <div @scroll="this.handleScroll" class="filter-container overflow-x-auto">
        <div class="flex justify-center">
          <CategoryButton
            v-for="category in this.$store.state.categories"
            :key="category.id"
            :category="category"
          />
        </div>
      </div>
      <div
        class="cat-description"
        :class="{ hideText: this.CheckVisibility() }"
      >
        <VMarkdownView :content="this.GetCategoryDescription" />
      </div>
    </div>

    <div
      @scroll="this.handleScroll"
      class="filter-container-inner shadow-bottom"
      v-if="this.$store.state.sidebarOpen || !this.$store.state.isMobile"
    >
      <div
        class="w-full letterContainer"
        v-for="letter in Object.keys(this.GroupIt)"
        :key="letter"
      >
        <h1 class="">{{ letter.toUpperCase() }}</h1>
        <div class="singles">
          <SinglePOIOverview
            v-for="poi in this.GroupIt[letter]"
            :key="poi.id"
            :poiData="poi"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SinglePOIOverview from "../components/SinglePOIOverview.vue";
import { VMarkdownView } from "vue3-markdown";
import CategoryButton from "./CategoryButton.vue";
import ScrollMixin from "./ScrollMixin.js";

export default {
  name: "Overview",
  components: { SinglePOIOverview, CategoryButton, VMarkdownView },
  mixins: [ScrollMixin],

  computed: {
    GetCategoryDescription() {
      var currentCat = this.$store.state.categories.find(
        (c) => c.attributes.Name == this.$store.state.currentCategory
      );

      return currentCat == null
        ? "Alle Orte"
        : currentCat.attributes.Description;
    },
    GroupIt() {
      var pois = this.GetPOIs;
      return pois.reduce((acc, cur) => {
        const firstLetter = cur.attributes.Title[0].toLowerCase();
        return {
          ...acc,
          [firstLetter]: [...(acc[firstLetter] || []), cur],
        };
      }, {});
    },
    GetPOIs() {
      var filteredPois = this.$store.state.pois.filter((poi, index) => {
        if (poi.attributes.IsAR) {
          return false;
        }

        if (this.$store.state.currentCategory == "All") {
          return true;
        } else if (typeof poi.attributes.categories == "undefined") {
          return false;
        }

        var cat = Object.values(poi.attributes.categories.data).find(
          (cat) => cat.attributes.Name == this.$store.state.currentCategory
        );

        return cat != null;
      });

      var doubleFilteredPois = filteredPois.filter((poi, index) => {
        var unique = filteredPois.findIndex(
          (poi_double) => poi_double.attributes.Title === poi.attributes.Title
        );

        return unique === index;
      });

      var sortedPois = doubleFilteredPois.sort((a, b) => {
        if (a.attributes.Title < b.attributes.Title) return -1;
        if (a.attributes.Title > b.attributes.Title) return 1;
        return 0;
      });

      return sortedPois;
    },
  },

  methods: {
    CheckVisibility() {
      return this.$store.state.isMobile && this.$route.params.id != "";
    },
  },
};
</script>

<style lang="scss" type="text/tailwindcss">
@layer components {
  .filterButton {
    @apply bg-white h-10 p-2 m-1 whitespace-nowrap flex items-center;
  }
}
</style>

<style lang="scss" scoped type="text/tailwindcss">
.active {
  background-color: black;
  color: white;
}

.filter-container-outer {
  width: 100%;
  padding: 0.5rem;
  position: relative;
  z-index: 1000;

  background-image: url("../assets/HBPG_Paper_Background.jpg");
}
.filter-container {
  width: 100%;
  position: relative;
  z-index: 1000;
  flex: 1;
  display: flex;
}

.ausstellung .filter-container-outer {
  padding-left: 4rem;
  padding-right: 2rem;
}

.filter-container-inner {
  width: 100%;
  height: calc(100vh - 200px);
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../assets/HBPG_Paper_Background.jpg");
}

.ausstellung .filter-container-inner {
  padding: 0rem 2rem 2rem 2rem;
  height: calc(100vh - 220px);
}

.letterContainer {
  padding-top: 2rem;
}

.singles {
  padding-left: 2rem;
}
.overview-container {
  width: 600px;
  z-index: 1000;
}

.ausstellung.overview-container {
  width: 50%;
}

.cat-description {
  padding: 0.5rem;
  height: 110px;
}

.ausstellung .cat-description {
  padding: 1rem;
  height: 125px;
}

.hideText {
  display: none;
}

@media screen and (max-width: 900px) {
  .overview-container {
    position: fixed;
    width: 100%;
    top: $header-height;
    z-index: 999;
  }
  .filter-container {
    justify-content: start;
  }
  .cat-description {
    padding: 0.5rem;
    text-align: center;
    height: 120px;
  }

  .filter-container-inner {
    height: calc(100vh - 235px);
  }
  .letterContainer {
    padding-top: 0.5rem;
  }
}
</style>
