<template>
  <router-link
    class="filterButton hover:bg-grey"
    :class="{ mobileHoverWhite: this.$store.state.isMobile }"
    :style="{
      color:
        this.$store.state.currentCategory == category.attributes.Name
          ? 'white'
          : 'black',
      'background-color':
        this.$store.state.currentCategory == category.attributes.Name
          ? 'black'
          : 'white',
    }"
    :to="{ query: { category: category.attributes.Name } }"
  >
    {{ category.attributes.Visual_Name }}
  </router-link>
</template>

<script>
export default {
  name: "CategoryButton",
  components: {},
  props: ["category"],
  data() {
    return {};
  },

  computed: {
    activeClassObject() {
      return {
        active: this.isActive && !this.error,
        "text-danger": this.error && this.error.type === "fatal",
      };
    },
  },

  methods: {
    GetAmountCategory(categoryName) {
      var cat = Object.values(this.$store.state.categories).find(
        (c) => c.attributes.Name == categoryName
      );
      if (cat == null) {
        return 0;
      }
      return cat.attributes.pois.data.length;
    },
  },
};
</script>


