<template>
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 28.5C7.6 28.5 1.5 22.5 1.5 15C1.5 7.6 7.5 1.5 15 1.5C22.5 1.5 28.5 7.5 28.5 15C28.5 22.4 22.4 28.5 15 28.5Z"
      fill="white"
    />
    <path
      d="M15 3C21.6 3 27 8.4 27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3ZM15 0C6.7 0 0 6.7 0 15C0 23.3 6.7 30 15 30C23.3 30 30 23.3 30 15C30 6.7 23.3 0 15 0Z"
      fill="#1E1E1D"
    />
    <path
      d="M13.4004 10V7H16.4004V10H13.4004ZM13.5004 22.8V11.6H16.3004V22.9H13.5004V22.8Z"
      fill="#1E1E1D"
    />
  </svg>
</template>
