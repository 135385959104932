import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js'
import Vidle from 'v-idle-3'

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Vidle);

app.mount('#app');

