import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Landing from '../pages/LandingPage.vue'
import Map from '../pages/Map.vue'
import ARViewer from '../pages/ARViewer.vue'
import DMXTestPage from '../pages/DMXTestPage.vue'
import ImpressumPage from '../pages/ImpressumPage.vue'
import store from '@/store/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/map/:id?',
    name: 'Map',
    component: Map
  },
  {
    path: '/ar-viewer',
    name: 'ARViewer',
    component: ARViewer
  }, {
    path: '/dmx',
    name: 'DMXTestPage',
    component: DMXTestPage
  }
  , {
    path: '/impressum',
    name: 'ImpressumPage',
    component: ImpressumPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (Object.prototype.hasOwnProperty.call(to.query, 'ausstellung')) {
    store.commit("SetAusstellungsMode");
  }

  console.log("ROUTE ", to.params.id)

  if (to.params.id == null || typeof to.params.id == 'undefined') {
    to.params.id = ''
  }

  if (Object.prototype.hasOwnProperty.call(to.query, 'category')) {
    store.commit('SetCategory', to.query.category);

    if (to.query.category === null) {
      const query = Object.assign({}, to.query);
      delete query.category;
      to.query = query;
      console.log("remove category from url");
      next({ path: to.path, query: to.query });
      return;
    }
  }



  next();
})

export default router
