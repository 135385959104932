<template>
  <div class="h-full w-full" v-show="this.$store.state.pois.length != 0">
    <div
      v-if="!this.cameraAllowed"
      class="cameraText flex flex-col align-center justify-center"
    >
      <CameraIcon class="w-20" />
      <p class="pt-2 text-center">
        Bitte erlaube die Benutzung Deiner Smartphone-Kamera.
      </p>
    </div>

    <div v-if="this.cameraAllowed && this.arReady" class="ui-container">
      <p class="ar-text font-bold" v-if="this.currentPOI == null">
        Halte Deine Kamera auf das Bild, um es mit Augmented Reality zu
        erweitern
      </p>

      <p class="ar-text font-bold" v-if="this.currentPOI != null">
        Kannst Du die Perspektive des Bildes annehmen?
      </p>

      <div
        class="poi-button flex flex-row items-center fixed"
        v-if="this.currentPOI != null"
      >
        <div
          class="text-container justify-center bg-white flex flex-row items-center"
        >
          <span>{{ this.currentPOI.attributes.Title }} </span>
        </div>
        <router-link
          class="nav-button bg-black"
          :to="{
            name: 'Map',
            params: { id: this.currentPOI.id },
          }"
        >
          <ArrowRight />
        </router-link>
      </div>
    </div>

    <div class="wrapper">
      <a-scene
        mindar-image="imageTargetSrc: /targets.mind; uiError:no; uiLoading:yes; filterMinCF:0.001; filterBeta:100;missTolerance: 5;"
        color-space="sRGB"
        renderer="colorManagement: true, physicallyCorrectLights"
        vr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false"
      >
        <a-assets>
          <template v-for="targetIdx in this.targets" :key="targetIdx">
            <img
              v-if="this.GetImagesForTarget(targetIdx) != null"
              :id="'cms' + this.GetImagesForTarget(targetIdx).id"
              :src="
                mainConfig.CMS_BASE_URL +
                this.GetImagesForTarget(targetIdx).attributes.url
              "
              crossorigin="anonymous"
            />
          </template>
        </a-assets>

        <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>

        <a-entity
          v-for="targetIdx in this.targets"
          :key="targetIdx"
          :id="targetIdx"
          :ref="'target' + targetIdx"
          :mindar-image-target="'targetIndex: ' + targetIdx"
        >
          <a-image
            v-if="this.GetImagesForTarget(targetIdx) != null"
            id="preview"
            :src="'#cms' + this.GetImagesForTarget(targetIdx).id"
            :width="this.GetScale(targetIdx).width"
            :height="this.GetScale(targetIdx).height"
            :position="this.GetPosition(targetIdx)"
            :rotation="this.GetRotation(targetIdx)"
          />
        </a-entity>
      </a-scene>
    </div>
  </div>
</template>

<script>
import mainConfig from "../main.config";
import ArrowRight from "../assets/Icons/ArrowRight.vue";
import CameraIcon from "../assets/Icons/CameraIcon.vue";
export default {
  name: "ARViewer",
  components: { ArrowRight, CameraIcon },
  data() {
    return {
      mainConfig: mainConfig,
      assetsLoaded: false,
      targets: [0, 1, 2, 3, 4],
      currentPOI: null,
      lastPOI: null,
      cameraAllowed: false,
      arReady: false,
    };
  },
  mounted() {
    this.addEventListener();
    this.$store.commit("SetViewMode", "AR");

    console.log(navigator.mediaDevices);

    navigator.mediaDevices.enumerateDevices().then((r) => {
      r.map((device) => {
        if (device.kind == "videoinput") {
          this.cameraAllowed = true;
        }
      });
    });

    const sceneEl = document.querySelector("a-scene");
    sceneEl.addEventListener("arReady", (event) => {
      this.arReady = true;
    });
  },

  unmounted() {
    var uiElements = document.getElementsByClassName("mindar-ui-overlay");
    uiElements = [...uiElements];
    console.log("AR Viewer unmounted", uiElements);
    uiElements.map((e) => {
      document.body.removeChild(e);
    });
  },
  watch: {
    "$store.state.pois": function () {
      this.addEventListener();
    },
  },
  methods: {
    addEventListener() {
      console.log(this.$store.state.pois[0]);
      document
        .getElementsByTagName("a-scene")[0]
        .addEventListener("loaded", () => {
          this.targets.map((t) => {
            this.$refs["target" + t][0].addEventListener(
              "targetFound",
              (event) => {
                this.currentPOI = this.$store.state.pois.find((poi) => {
                  return poi.attributes.ARTargetIdx == event.currentTarget.id;
                });
              }
            );
            this.$refs["target" + t][0].addEventListener(
              "targetLost",
              (event) => {
                this.lastPOI = this.currentPOI;
                this.currentPOI = null;
                setTimeout(() => {
                  return document
                    .querySelector("a-scene")
                    .systems["mindar-image-system"].ui.showScanning();
                }, 1000);
              }
            );
          });
        });
    },
    GetARPois() {
      return this.$store.state.pois.filter(
        (poi) => poi.attributes.IsAR == true
      );
    },
    GetImagesForTarget(idx) {
      var poi = this.GetPOIForTarget(idx);

      if (poi == null || typeof poi == "undefined") {
        return null;
      }

      return poi.attributes.ARImage.data;
    },
    GetPOIForTarget(idx) {
      return this.GetARPois().find((poi) => poi.attributes.ARTargetIdx == idx);
    },
    GetPosition(idx) {
      var pos = "0 0 0";
      var poi = this.GetPOIForTarget(idx);

      if (poi == null || typeof poi == "undefined") {
        return pos;
      }

      pos =
        poi.attributes.ARPosition.x +
        " " +
        poi.attributes.ARPosition.y +
        " " +
        poi.attributes.ARPosition.z;

      return pos;
    },
    GetRotation(idx) {
      var rot = "0 0 0";
      var poi = this.GetPOIForTarget(idx);

      if (poi == null || typeof poi == "undefined") {
        return rot;
      }

      rot =
        poi.attributes.ARRotation.x +
        " " +
        poi.attributes.ARRotation.y +
        " " +
        poi.attributes.ARRotation.z;

      return rot;
    },
    GetScale(idx) {
      var dim = { width: 1, height: 1 };
      var poi = this.GetPOIForTarget(idx);

      if (poi == null || typeof poi == "undefined") {
        return dim;
      }

      dim.width = poi.attributes.ARWidth;
      dim.height = poi.attributes.ARHeight;

      return dim;
    },
  },
};
</script>
<style>
/* video {
  top: 0px !important;
  left: 0px !important;
  position: fixed;
  width: 2px !important;
  height: 200px !important;
} */

.wrapper {
  display: block;
  width: 100%;
  height: 100%;
}

.wrapper video {
  max-width: 1000%;
}

.mindar-ui-scanning .scanning .inner {
  background: linear-gradient(to right, black 10px, transparent 10px) 0 0,
    linear-gradient(to right, black 10px, transparent 10px) 0 100%,
    linear-gradient(to left, black 10px, transparent 10px) 100% 0,
    linear-gradient(to left, black 10px, transparent 10px) 100% 100%,
    linear-gradient(to bottom, black 10px, transparent 10px) 0 0,
    linear-gradient(to bottom, black 10px, transparent 10px) 100% 0,
    linear-gradient(to top, black 10px, transparent 10px) 0 100%,
    linear-gradient(to top, black 10px, transparent 10px) 100% 100% !important;
  background-repeat: no-repeat !important;
  background-size: 40px 40px !important;
}

.mindar-ui-scanning .scanning .inner .scanline {
  background: black !important;
}
</style>

<style lang="scss" scoped>
@media screen and (orientation: landscape) {
  .ui-container {
    display: none;
  }
}

.cameraText {
  height: calc(100% - 140px);
  padding: 2rem;
  display: flex;
  align-items: center;
  p {
    font-size: 1.2rem;
  }
}

.ar-text {
  position: relative;
  left: 40px;
  top: 20px;
  width: 80%;
}
.poi-button {
  bottom: 1rem;
  left: 40px;
}
.text-container {
  height: 45px;
  z-index: 2;
  cursor: auto;
  min-width: 220px;
}
span {
  white-space: nowrap;
  padding-left: 0.6rem;
  padding-right: 0.5rem;
  margin-left: -0.4rem;
}
.container {
  margin: 20px auto;
  position: relative;
  height: 400px;
  width: 600px;
  overflow: hidden;
}

#scanning-overlay {
  color: white;
  font-size: 5rem;
}
.ui-container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  min-height: 140px;
  z-index: 1;
  background-image: url("../assets/HBPG_Paper_Background.jpg");
}

.button-container {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
