<template>
  <div class="gallery-wrapper h-full" ref="wrapper" v-if="GetImages.length > 0">
    <button
      @click="StartFullScreen(false)"
      class="nav-button bg-black absolute top-0 right-0 z-10"
      v-if="this.$store.state.showFullScreen"
    >
      <Exit />
    </button>
    <div
      class="swiper-container relative bg-white"
      :style="{ height: GetHeight }"
    >
      <div
        class="swiper-wrapper bg-white"
        :class="{ swiperContainerFullscreen: $store.state.showFullScreen }"
      >
        <div
          class="swiper-slide bg-white"
          lazy="true"
          v-for="(image, index) in GetImages"
          :key="image.id + '_' + index"
        >
          <figure>
            <div class="relative image-wrapper">
              <img
                class="image-inner"
                loading="swiper-lazy"
                :src="mainConfig.CMS_BASE_URL + GetImageUrl(image)"
              />
              <div
                class="absolute bottom-0 left-0 w-full h-full flex flex-row justify-between"
              >
                <button class="gallery-button-prev w-1/5 h-full"></button>

                <button class="gallery-button-next w-1/5 h-full"></button>
              </div>
            </div>

            <figcaption class="pt-5 text-center">
              {{
                image.attributes.caption == "" ||
                image.attributes.caption == null
                  ? "Noch keine Beschreibung vorhanden"
                  : image.attributes.caption
              }}
            </figcaption>
          </figure>
        </div>
      </div>
      <div
        class="gallery-pagination flex flex-center w-full pb-5 z-10 relative"
      ></div>
      <button
        v-if="!this.$store.state.isMobile && !this.$store.state.showFullScreen"
        @click="StartFullScreen(true)"
        class="nav-button bg-white absolute right-0 bottom-0 z-10"
      >
        <Zoom />
      </button>
      <!-- Add Arrows -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/swiper-bundle.esm.browser.js";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper";
import mainConfig from "../main.config";
import computedDimensions from "@/Mixins/VueComputedDimensions";
import Exit from "../assets/Icons/Exit.vue";
import Zoom from "../assets/Icons/Zoom.vue";
export default {
  name: "Gallery",
  mixins: [computedDimensions("wrapper")],
  components: { Exit, Zoom },
  props: {
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    poi: {
      type: Object,
      required: true,
      swiper: null,
      switch: false,
    },
  },
  data() {
    return {
      swiper: null,
      mainConfig: mainConfig,
    };
  },
  watch: {
    "$props.key": function () {
      console.log("key changed");
    },
  },
  computed: {
    GetHeight() {
      if (this.$store.state.showFullScreen) {
        return "90%";
      } else {
        return "auto";
      }
    },
    GetImages() {
      let collection = [];

      if (this.poi.Title_Image.data) {
        collection.push(this.poi.Title_Image.data);
      }

      if (this.poi.Images.data) {
        collection = collection.concat(this.poi.Images.data);
      }

      return collection;
    },
  },
  mounted() {
    this.InitSwiper();

    console.log("Mounted Gallery");
  },
  methods: {
    StartFullScreen(state) {
      this.$store.commit("ShowFullScreen", state);
    },
    onidle() {
      this.switch = true;
      this.NextImage();
    },
    GetImageUrl(image) {
      let url = image.attributes.url;

      if (
        Object.prototype.hasOwnProperty.call(
          image.attributes.formats,
          "medium"
        ) &&
        !this.isFullscreen
      ) {
        url = image.attributes.formats.medium.url;
      }
      if (
        Object.prototype.hasOwnProperty.call(
          image.attributes.formats,
          "large"
        ) &&
        this.isFullscreen
      ) {
        url = image.attributes.formats.large.url;
      }

      return url;
    },
    InitSwiper() {
      Swiper.use([Navigation, Pagination]);

      this.swiper = new Swiper(".swiper-container", {
        speed: 500,
        effect: "fade",
        loop: true,
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        pagination: {
          el: ".gallery-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".gallery-button-next",
          prevEl: ".gallery-button-prev",
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: true,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.swiperContainerFullscreen {
  height: calc(100% - 30px);
}
swiper-container {
  width: 100%;
  max-width: 800px;
}

.swiper-slide {
  padding: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  align-items: center;
  opacity: 0 !important;
  transition-duration: 0.25s;
  &.swiper-slide-active {
    opacity: 1 !important;
  }
}

.image-inner {
  max-height: 80vh;
}

figure .image-wrapper {
  display: flex;
  justify-content: center;
  //padding-left: 30px;
  //   &:before {
  //     content: "";
  //     display: block;
  //     width: 30px;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0px;
  //     background: $yellow;
  //   }
}
</style>
