<template>
  <svg wviewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 11C29.6 11 35 16.4 35 23C35 29.6 29.6 35 23 35C16.4 35 11 29.6 11 23C11 16.4 16.4 11 23 11ZM23 8C14.7 8 8 14.7 8 23C8 31.3 14.7 38 23 38C31.3 38 38 31.3 38 23C38 14.7 31.3 8 23 8Z"
      fill="#1E1E1D"
    />
    <path d="M22 40.9969V29.7969H24.8V41.0969H22V40.9969Z" fill="#1E1E1D" />
    <path d="M22 16.2V5H24.8V16.3H22V16.2Z" fill="#1E1E1D" />
    <path
      d="M41.5008 24.6406L30.3008 24.6406L30.3008 21.8406L41.6008 21.8406L41.6008 24.6406L41.5008 24.6406Z"
      fill="#1E1E1D"
    />
    <path
      d="M16.2 24.6406L5 24.6406L5 21.8406L16.3 21.8406L16.3 24.6406L16.2 24.6406Z"
      fill="#1E1E1D"
    />
  </svg>
</template>
