<template>
  <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="12.9004"
      y="30.125"
      width="24"
      height="4"
      rx="1"
      transform="rotate(-45 12.9004 30.125)"
      fill="white"
    />
    <rect
      x="30.125"
      y="32.9922"
      width="24"
      height="4"
      rx="1"
      transform="rotate(-135 30.125 32.9922)"
      fill="white"
    />
  </svg>
</template>